<script>
import { EPMC_FUNDERS as FUNDERS } from '@/config'

export default {
  metaInfo() {
    return {
      title: 'Funders - About - Europe PMC',
    }
  },
  filters: {
    getGrantLink(funder) {
      return (
        '/grantfinder/results?ga=' + encodeURIComponent(funder.name) + '&page=1'
      )
    },
  },
  data() {
    return {
      FUNDERS,
    }
  },
  methods: {
    getImageSrc(funder) {
      return require('@/assets/funders_logos/' + funder.imageUrl)
    },
    getArticlesFundedQuery(funder, type) {
      console.log(type)
		if(type === "IARC") 
			return funder.iarc  
		return 'GRANT_AGENCY:"' + encodeURIComponent(funder.value[0]) + '"'
    },
  },
}
</script>
<template>
  <div id="funders-page">
    <div id="funders-page-content" class="grid-row">
      <div class="col-11">
        <h1>Funders</h1>
        <p>
          Europe PMC has {{ FUNDERS.length }} research funders, across Europe.
          The Europe PMC funders expect:
        </p>
        <ul>
          <li>
            Research outputs arising from research that we fund to be made
            freely and readily available;
          </li>
          <li>
            Electronic copies of any biomedical research papers that have been
            accepted for publication in a peer-reviewed journal, and are
            supported in whole or in part by funding from any of the Europe PMC
            Funders, to be made available through PubMed Central (PMC) and
            Europe PMC, as soon as possible and in any event within six months
            of the journal publisher's official date of final publication;
          </li>
          <li>
            Authors and publishers, if an open access fee has been paid, to
            license research papers such that they may be freely copied and
            re-used for purposes such as text and data mining, provided that
            such uses are fully attributed. This is also encouraged where no fee
            has been paid.
          </li>
        </ul>
        <p>
          For further information, please refer to each funder's individual open
          access policy using the links below.
        </p>

        <div
          v-for="funder in FUNDERS"
          :key="funder.name"
          class="funders-container"
        >
          <div class="funder-image-container">
            <a
              :href="funder.url"
              class="image-link"
              :style="{ width: funder.imageWidth + 'px !important' }"
              :aria-label="'Link to ' + funder.name + ' website'"
            >
              <img
                :src="getImageSrc(funder)"
                :style="{ width: funder.imageWidth + 'px !important' }"
                :alt="funder.name + ' logo'"
              />
            </a>
          </div>
          <ul>
            <li>
              <a :href="`/search?query=${getArticlesFundedQuery(funder)}`">
                Publications funded
				{{ funder.abbr === "WHO" ? '(all WHO)' : '' }}
              </a>
            </li>
            <li v-if="funder.iarc">
              <a :href="`/search?query=${getArticlesFundedQuery(funder, 'IARC')}`">
                Publications funded
				{{ funder.abbr === "WHO" ? '(IARC only)' : '' }}
              </a>
            </li>
            <li v-if="funder.oaPolicy">
              <a
                :href="funder.oaPolicy"
                :aria-label="'Link to ' + funder.name + ' open access policy'"
              >
                Open access policy <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
            <li v-if="funder.oaReq">
              <a
                :href="funder.oaReq"
                :aria-label="
                  'Link to ' + funder.name + ' open access requirements'
                "
              >
                Open access requirements
                <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
            <li>
              <a :href="funder | getGrantLink">Grants</a>
            </li>
            <li>
              <a
                :href="`/funder-dashboard?funderName=${encodeURIComponent(
                  funder.value[0]
                )}`"
              >
                Funder dashboard
              </a>
            </li>
            <li v-if="funder.other" v-html="funder.other"></li>
          </ul>
        </div>

        <h2>Supported by</h2>
        <div>
          Europe PMC receives in-kind support from European Molecular Biology
          Laboratory
        </div>
        <div class="embl-container">
          <div class="embl-image-container">
            <a
              href="//www.embl.de/"
              class="embl-image-link"
              aria-label="Link to EMBL website"
            >
              <img src="@/assets/embl.png" class="embl-image" alt="EMBL logo" />
            </a>
          </div>
          <ul class="embl-links">
            <li>
              <a
                href="//www.embl.org/about/info/szilard-library/wp-content/uploads/2020/10/IP-66-EMBL-Open-Access-Policy.pdf"
                aria-label="Link to EMBL open access policy"
              >
                Open access policy <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
            <li>
              <a
                href="//www.embl.org/about/info/szilard-library/open-access/"
                aria-label="Link to EMBL open access information"
              >
                Open access information <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.ebi.ac.uk/long-term-data-preservation"
                aria-label="Link to EMBL long-term data preservation information"
              >
                Long-term data preservation
                <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#funders-page-content {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
  .funders-container {
    padding: ($base-unit * 7) ($base-unit * 6);
    &:nth-child(odd) {
      background-color: lighten($epmc-darker-grey, 64%);
    }
    .funder-image-container {
      @media screen and (min-width: $breakpoint-extra-small) {
        display: inline-block;
      }
      vertical-align: middle;
      width: 50%;
      .image-link {
        display: block;
        margin: auto;
      }
    }
    ul {
      display: inline-block;
      vertical-align: middle;
      @media screen and (min-width: $breakpoint-extra-small) {
        width: 40%;
      }
    }
  }
  .embl-container {
    padding: ($base-unit * 7) ($base-unit * 6);
    .embl-image-container {
      @media screen and (min-width: $breakpoint-extra-small) {
        display: inline-block;
      }
      vertical-align: middle;
      width: 50%;
      .embl-image-link {
        display: block;
        margin: auto;
        width: $base-unit * 45 !important;
        .embl-image {
          width: $base-unit * 45 !important;
        }
      }
    }
    .embl-links {
      display: inline-block;
      vertical-align: middle;
      @media screen and (min-width: $breakpoint-extra-small) {
        width: 40%;
      }
    }
  }
}
</style>
